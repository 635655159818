.titolo {
    text-align: center;
    font-size: 40px;
    
}

.conME {
    text-align: center;
    font-size: xx-large;
}

.metodo {
    text-align: center;
    font-size: xx-large;
}

.metodo .instagram {
    text-decoration: none;
    color: orangered;

}

.metodo .telegram {
    text-decoration: none;
    color: cornflowerblue;
}

.email {
    font-size: xx-large;
    text-align: center;
}

.email a {
    text-decoration: none;
    color: cadetblue;
}