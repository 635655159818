.contenitore {
    background: linear-gradient(200deg, lightgreen, lightblue);
    width: 1431px;
    height: 460px;
    margin-top: 125px;
    position: absolute;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra gli elementi lungo l'asse orizzontale */
    padding-left: 50px;
  }
  
  .nome {
    text-align: center;
    font-size: 50px;
  }
  
  .menu {
    display: flex;
    justify-content: center; /* Centra gli elementi lungo l'asse orizzontale */
    margin-top: 30px;
  }
  
  .menu .item {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    color: #333333;
    margin-right: 200px;
    margin-left: 200px;
  }
  
  .menu .item:hover {
    cursor: pointer;
    color: lightcoral;    
  }