.About {
    text-align: center;
    font-size: 40px;
}

.pres {
text-align: center;
font-size: xx-large;

}

.container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 650px;
    background: linear-gradient(320deg, green, lawngreen);
    border-radius: 5px;
    border-color: black;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
}

.container img {
    width: 300px;
    height: 380px;
    border-radius: 50%;
    margin-left: 570px;
}